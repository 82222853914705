.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 40px;
        height: 40px;
    }

    .overlay {
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5;
    }
}

/* IE10+ specific styles go here */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .loading {
        img {
            @include animation(rotation 0.6s linear infinite);
        }
    }
}

/* Edge specific styles go here */
@supports (-ms-ime-align: auto) {
    .loading {
        img {
            @include animation(rotation 0.6s linear infinite);
        }
    }
}

@keyframes rotation {
    from {
        @include transform(rotate(0));
    }
    to {
      @include transform(rotate(360deg));
    }
}

/* Resin Pin New Flow */
@keyframes load {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader {
    border: 4px solid rgba($yellow, 0.3);
    border-left: 4px solid $yellow;
    animation: load 1s infinite linear;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}
.inner-loading-container {
    display: flex;
    flex-flow: column;
    min-height: 200px;
    justify-content: center;
    align-items: center;
    img {
        width: 40px;
        height: 40px;
    }
}
/* ================= */
