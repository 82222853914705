.nature-purpose-container {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .nature-purpose_content {
        display: flex;
        flex-direction: row;
        gap: 72px;

        >div {
            gap: 12px;
            margin: 0px !important;
            padding: 0px !important;
        }

        >div:first-child {
            border-right: 1px solid gray;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 40px;

            >div:first-child {
                border-right: none;
                border-bottom: 1px solid gray;
                padding-bottom: 40px !important;
            }
        }
    }

    .nature-purpose_label {
        display: flex;
        flex-direction: column;
        line-height: 1.4;
        margin-bottom: 40px;

        h4 {
            font-size: 18px;
        }
    }

    .nature-purpose_options {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .nature-purpose_label_error {
        color: red;
        margin-bottom: -24px;
    }

    .btn-control-container {
        margin-top: 24px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.form-check {
    $fc: &;
    padding-left: 0;

    &+.form-check {
        margin-top: 16px;
    }

    &-input {
        display: none;
    }

    &-label {
        color: #231f20;
        cursor: pointer;

        &:before {
            content: '';
            width: 24px;
            height: 24px;
            border: solid 2px #979797;
            display: inline-block;
            vertical-align: middle;
            margin-top: -4px;
            margin-right: 20px;
            background-color: transparent;
            box-shadow: 0 0 0 1px transparent;
            margin-left: 1px;
        }
    }

    &-input[type='radio'] {
        +#{$fc}-label:before {
            border-radius: 50%;
        }

        &:checked {
            +#{$fc}-label:before {
                background-color: $yellow;
                border: 4px solid #fff;
                box-shadow: 0 0 0 2px lighten($yellow, 20%);
            }
        }
    }

    &-input[type='checkbox'] {
        +#{$fc}-label {
            padding-left: 56px;
            position: relative;
            font-weight: 300;
            user-select: none;

            @media (max-width: 768px) {
                padding-left: 60px;
                font-size: 16px !important;
            }

            &:before {
                border-radius: 0;
                position: absolute;
                left: 0;
                top: -4px;
                margin: 1px 0 0;

                width: 28px;
                height: 28px;
            }
        }

        &:checked {
            +#{$fc}-label:before {
                content: '';
                border: 0;
                box-shadow: none;
                background: url('../../images/checkbox_seleted.svg') no-repeat center;
                background-size: contain;
            }
        }
    }
}