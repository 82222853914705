.layout{
    footer{
        .page-footer{
            border-top: 8px solid $yellow;
            padding-top: 25px;
            margin-bottom: 25px;

            .info{
                .row{
                    .icon{
                        width: 46px;
                        height: 46px;
                        margin-right: 20px;
                        margin-left: 25px;
                    }
                    .text{
                        margin-top: 12px;
                        font-size: 12px;
                        color: $gray3;
                    }

                    &.mobile{
                        margin-top: 13px;
                    }
                }
            }

            .branding{
                .row{
                    .text{
                        font-size: 12px;
                        color: $gray3;
                        text-align: right;
                        margin-right: 25px;
                    }
                    img{
                        display: block;
                        width: 174px;
                        padding-bottom: 20px;
                        margin-left: auto;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}

// mobile device
@media (max-width: $screen-sm-min){
    .layout{
        .bottom{
            footer{
                .page-footer{
                    padding-top: 10px;
                    margin-bottom: 5px;

                    .info{
                        .row{
                            .text{
                                font-size: 8px;
                                margin-top: 5px;
                                margin-left: 15px;
                                margin-right: 15px;
                            }

                            &.mobile{
                                margin-top: 0;
                            }
                        }
                    }

                    .branding{
                        margin-top: 15px;
                        border-top: 1px solid $gray7;

                        .row{
                            .text{
                                float: left;
                                margin-top: 20px;
                                margin-right: 0px;
                                margin-left: 15px;
                                font-size: 8px;
                            }
                            img{
                                float: right;
                                padding-top: 15px;
                                padding-bottom: 0;
                                margin-right: 10px;
                                width: 90px;
                            }
                        }
                    }
                }
            }
        }
    }
}
