*{
  font-family: 'Roboto', sans-serif;
  color: black;
  font-size: 16px;
  line-height: normal;
  font-weight: 300;
}

html,
body{
  width: 100%;
  height: 100%;
}

body{
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

#root,
.wrapper,
.layout{
  height: 100%;
}

h1,
h2{
  font-family: 'Roboto', sans-serif;
  font-weight: 300;

  *{
    font-size: inherit;
    font-weight: inherit;
  }
}

p{
  font-size: 20px;
  white-space: pre-line;
  margin-bottom: 20px;

  strong{
    font-size: 20px;
  }

  .error{
    display: block;
    color: $red;
  }
}

.container{
  position: relative;
}

.layout{
  .top{
    overflow: hidden;
    margin: 0 auto -170px;
    min-height: 100%;
    height: auto !important;

    header{
      height: 305px;
      background-color: $gray;
      padding: 40px 0;

      .progress-container{
        width: 100%;
        height: 10px;
        margin-bottom: 70px;

        .progress-bar{
          background-color: $yellow;
        }
      }

      h1{
        font-size: 32px;
        color: white;
        margin: 25px 0 20px;
      }

      p{
        color: white;
      }
    }

    .content{
      padding: 60px 0 50px;
    }
  }

    .bottom,
    .push{
      height: 170px;
    }

  .bottom{
    footer{
      p{
        font-size: 10px;
        color: white;
      }
    }
  }
}

// mobile device
@media (max-width: $screen-sm-min){
  *,
  p{
    font-size: 14px;

    strong{
      font-size: 14px;
    }
  }

  img:not(.logo){
    width: 48px;
    height: auto;
  }

  .layout{
    .top{
      margin-bottom: -132px;

      header{
        height: 200px;
        padding: 25px 0;
        background-image: none !important;

        .progress-container{
          height: 5px;
          margin-bottom: 0;
        }

        h1{
          font-weight: 500;
          margin-bottom: 10px;
        }

        h1,
        p{
          font-size: 14px;
        }
      }

      .content{
        padding-top: 25px;
      }
    }

    .bottom,
    .push{
      height: 132px;
    }

    .bottom{
      footer{
        p{
          text-align: center;
        }
      }
    }
  }
}

/* Resin Pin New Flow */
svg {
  height: 100%;
  width: 100%;
}

.font-bold {
  color: $gray;
  font-weight: 700;
}
.input {
  &-container {
    .has-error {
      .input-control {
        border-color: $red;
      }
    }
    .help-block {
      min-height: 20px;
      text-align: left;
    }
    .image-container {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 32px;
      cursor: pointer;
    }
    img{
      width: 100%;
      height: auto;
    }
  }
  &-label {
    color: $gray;
    font-weight: 300;
  }
  &-control {
    height: 48px;
    border: 1px solid $bd-grey;
    background-color: $white;
    padding: 12px 15px;
    width: 100%;
    &:hover {
      border: 1px solid $yellow;
    }
    &:focus {
      border: 1px solid $yellow;
      outline: none;
    }
  }

}
.header {
  background-color: $gray;
  min-height: 160px;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  @media (min-width: $screen-xs-min) {
    min-height: 200px;
    height: 305px;
    padding: 40px 0;
  }
  &-image {
    display: none;
    @media (min-width: $screen-xs-min) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 305px !important;
      width: auto !important;
    }
  }
  &-content {
    display: flex;
    flex-flow: column;
    .step-otp__header {
      order: 1;
      @media (min-width: $screen-xs-min) {
        order:0;
      }
    }
    .single-mcq__header {
      order: 1;
      @media (min-width: $screen-xs-min) {
        order:0;
      }
    }
  }
  p {
    color: $white;
    margin-bottom: 8px;
    @media (min-width: $screen-xs-min) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }
  .heading {
    font-weight: bold;
    font-size: 14px;
    @media (min-width: $screen-xs-min) {
      font-weight: normal;
      font-size: 32px;
      line-height: 40px;
    }
  }
  .mb-0 {
    margin-bottom: 0;
  }
  &-container {
    position: static;
    margin: auto !important;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }
  .progress-circle {
    margin-bottom: 0;
    @media (min-width: $screen-xs-min) {
      margin-bottom: 23px;
    }
  }
  .icon {
    max-width: 45px;
    margin-bottom: 16px;
  }
}

.page-content {
  min-height: calc(100vh - 200px - 101px);
  padding: 25px 0;
  @media (min-width: $screen-xs-min) {
    min-height: calc(100vh - 305px - 131px);
    padding: 24px 0 60px 0;
  }
  .credit-card-selection &{
    @media (min-width: $screen-xs-min) {
      min-height: calc(100vh - 320px - 131px);
      padding: 24px 0 60px 0;
    }
  }
}

.footer {
  border-top: 8px solid $yellow;
  p {
    font-size: 8px;
    margin: 0;
    padding: 16px 0;
    @media (min-width: $screen-xs-min) {
      font-size: 12px;
    }
  }
  .info {
    border-bottom: 1px solid #b3b3b3;
    @media (min-width: $screen-xs-min) {
      border-bottom: none;
      display: flex;
      align-items: center;
      padding: 16px 0;
    }
  }
  .branding {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      height: 23px;
      width: auto;
      order: 1;
      @media (min-width: $screen-xs-min) {
        order: 0;
        height: 44px;
      }
    }
    @media (min-width: $screen-xs-min) {
      display: block;
      text-align: right;
      padding: 16px 0;
    }
  }
}
/* ================= */
