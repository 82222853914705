.progress-circle {
  position: relative;
  width: 70px;
  height: 70px;

  .CircularProgressbar {
    width: 70px;

    .CircularProgressbar-path {
      stroke: white;
      stroke-linecap: butt;
      transition: stroke-dashoffset 0.5s ease 0s;
    }

    .CircularProgressbar-trail {
      stroke: $bright-blue;
    }
  }

  .seconds {
    color:white;
    width: 70px;
    position: absolute;
    z-index: 100;
    text-align: center;
    top: 13px;
    left: 0;
    font-size: 3rem;
    line-height: 3rem;

    span {
      color:white;
      display: block;
      font-size: 10px;
      margin-top: -1px;
    }
  }
}

.idle-timer {
  margin-top: 30px;
  width: 100%;
}

#reachedTimeout {
  .progress-circle {
    .CircularProgressbar {
      .CircularProgressbar-path {
        stroke: black;
      }

      .CircularProgressbar-trail {
        stroke: $bright-blue;
      }
    }

    .seconds {
      color: black;
      span {
        color: black;
      }
    }
  }
}
