.btn-container {
    display: inline-block;
}

.btn {
    min-width: 210px;
    height: 48px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 44px;
    text-align: center;
    color: $gray2;
    padding: 0 25px;
    margin-top: 25px;
    border: 1px solid black;
    @include borderRadius(100px);

    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        outline: none;
    }

    &:active {
        box-shadow: none;

        &:focus {
            outline: none;
        }
    }

    &:focus,
    &:active:focus,
    &.active:focus,
    &.focus,
    &:active.focus,
    &.active.focus {
        outline: none;
        box-shadow: none;
    }

    &.small {
        padding: 8px 15px;
    }

    + .btn {
        margin-right: 40px;
    }
}

.btn-solid {
    height: 48px;
    font-size: 16px;
    line-height: 46px;
    color: $gray2;
    text-shadow: none;
    background: $yellow;
    border-color: transparent;
    transition: background-color 0.3s ease-in-out;

    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        background: lighten($yellow, 10%);
    }

    &.disabled,
    &[disabled] {
        background: lighten(mix($yellow, $gray6, 75%), 20%);
        color: $gray2;
        text-shadow: -1px -1px 1px rgba(255, 255, 255, 1);
    }
}

.btn-contour {
    transition: box-shadow 0.35s cubic-bezier(0.39, 0.575, 0.565, 1);
    background-color: transparent;
    color: black;
    text-shadow: none;
    @include boxSizing(border-box);

    &:hover {
        box-shadow: none;
    }

    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        background: none;
    }

    &.disabled,
    &[disabled] {
        background: $gray8;
        text-shadow: -1px -1px 1px rgba(255, 255, 255, 1);
        border-color: $gray7;
    }

    &.inverse {
        border-color: white;
        color: white;
        background: none;
    }

    &.white {
        border-color: white;
        color: white;
    }
    &.mcq {
        min-height: 40px;
        height: auto;
        white-space: normal;
        text-align: left;
        padding-right: 72px;
        border-radius: 4px;
        position: relative;
        border: 2px solid $gray10;
        background-color: $gray10;
        &:hover {
            background-color: $gray9;
            border: 2px solid $gray9;
        }
        .checked-icon {
            position: absolute;
            width: 24px;
            height: auto;
            right: 24px;
            top: calc(50% - 12px);
        }
    }
}

// mobile device
@media (max-width: $screen-sm-min) {
    .btn,
    .btn-solid,
    .btn-contour {
        display: block;
        width: 210px;
        min-width: 210px;
        height: 40px;
        font-size: 14px;
        line-height: 38px;
        margin: 20px auto 0;

        + .btn {
            margin-right: auto;
        }
    }

    .btn-container {
        display: block;

        .pull-right {
            float: none !important;
        }
    }
    .btn-contour {
        &.mcq {
            min-height: 40px;
            height: auto;
            white-space: normal;
            padding-left: 12px;
            padding-right: 48px;
            border-radius: 4px;
            &:hover {
                background-color: $gray10;
                border: 2px solid $gray10;
            }
            .checked-icon {
                width: 24px;
                height: 24px;
                right: 12px;
                top: calc(50% - 12px);
                img {
                    width: 24px;
                    height: auto;
                }
            }
        }
    }
}
/* Resin Pin New Flow */
.btn-control-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    @media (min-width: $screen-xs-min) {
        flex-flow: row;
    }
}

.tyme-btn {
    height: 48px;
    padding: 12px 20px;
    font-size: 14px;
    line-height: 24px;
    color: $gray;
    margin: 0;
    transition: all 0.2s ease-in-out;
    &:hover {
        color: $gray;
        opacity: 0.8;
        transform: scale(1.05);
    }
    @media (min-width: $screen-xs-min) {
        padding: 12px 80px;
        font-size: 16px;
    }
    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}

.btn {
    &-secondary {
        order: 2;
        border: 1px solid $bd-grey;
        background-color: $white;
        @media (min-width: $screen-xs-min) {
            order: 1;
            margin-right: 30px;
        }
    }
    &-primary {
        order: 1;
        border: 1px solid transparent;
        background-color: $yellow;
        margin: 0 0 20px 0 !important;
        &:disabled {
            div {
                color: $text-grey;
            }
            background-color: $btn-disabled-bg;
            opacity: 1;
            &:hover {
                background-color: $btn-disabled-bg;
                border-color: transparent;
            }
        }
        @media (min-width: $screen-xs-min) {
            order: 2;
            margin: 0 !important;
        }
        &:hover {
            background-color: $yellow;
            border: 1px solid transparent;
        }
        &:active,
        &:focus {
            background-color: $yellow;
            color: $gray;
            border: 1px solid transparent;
        }
        &:active:hover {
            background-color: $yellow;
            color: $gray;
            border: 1px solid transparent;
        }
    }
    &-link {
        height: auto;
        padding: 0;
        border: none;
        div {
            color: $blue-link;
            justify-content: center;
        }
        &:hover {
            color: $blue-link;
            text-decoration: none;
        }
        @media (min-width: $screen-xs-min) {
            div {
                justify-content: flex-start;
            }
        }
    }
}
/* ================= */
