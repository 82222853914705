body{
  &.mcq-select{
    .layout{
      .top{
        header{
          .progress-container{
            margin-bottom: 25px;
          }
        }
      }
    }
  }

  &.mcq-select{
    .mcq-btn-wrapper{
      text-align: center;
      ul{
        padding: 0px;
        list-style-type: none;

        li{
          text-align: center;
          margin-top: 16px;

          button{
            margin: 3px;
            width: 470px;
            &.btn-contour{
              &.selected{
                border: 2px solid $yellow;
                background-color: $white;
              }
            }
          }
        }
      }
      .next-btn{
        border: none;
        background-color: $yellow;
        color: $gray;
        &:hover{
          cursor: pointer;
        }
        &.disabled{
          background-color: $gray8;
          color: $gray4;
          &:hover{
            cursor: unset;
          }
        }
      }
    }
  }

  &.mcq-confirm{
    .question{
      margin: 0px;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .answer{
      margin-bottom: 30px;
    }
  }
}

// mobile device
@media (max-width: $screen-sm-min){
  body{
    &.mcq-select{
      .layout{
        .top{
          header{
            .progress-container{
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &.mcq-select{
      .mcq-btn-wrapper{
        ul{
          padding: 0px;
          list-style-type: none;

          li{
            text-align: center;
            margin-top: 16px;

            button{
              margin: 3px auto;
              width: 280px;
            }
          }
        }
      }
    }

    &.mcq-confirm{
      .question{
        margin: 0px;
        margin-bottom: 5px;
        font-weight: bold;
      }

      .answer{
        margin-bottom: 30px;
      }
    }
  }
}

/* Resin Pin New Flow */
.single-mcq-container {
  .progress-circle {
    margin: auto;
    .seconds,
    span {
      color: $gray;
    }
  }
  .mcq-counter {
    text-align: center;
  }
  .mcq-question {
    text-align: center;
  }
  .btn-control-container {
    justify-content: center;
  }
}
.answer {
  &-container {
    display: flex;
    justify-content: space-between;
    padding: 14px 20px 14px 24px;
    background: rgba($bd-grey, 0.1);
    border-radius: 4px;
    border: 2px transparent solid;
    margin-bottom: 16px;
    width: 100%;
    align-items: center;
    transition: all 0.2s ease-in-out;
    @media (min-width: $screen-xs-min) {
      width: 320px;
    }
    &:hover {
      background: rgba($bd-grey, 0.3);
      cursor: pointer;
      transform: scale(.95);
    }
    &.selected {
      border: 2px solid #fdc600;
      background: $white;
      img {
        opacity: 1;
      }
      &:hover {
        transform: scale(1);
      }
    }
    img {
      width: 24px;
      height: 24px;
      opacity: 0;
      transition: opacity .2s ease-in-out;
    }
  }
  &-list {
    &-container {
      display: flex;
      flex-flow: column;
      margin-bottom: 40px;
      align-items: center;
    }
  }
}
/* ================= */
