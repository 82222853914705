body{
  &.password{
    form{
      .body{
        margin-bottom: 30px;
      }

      .field-container{
        position: relative;
        display: inline-block;

        .password-toggle{
          position: absolute;
          top: 0;
          right: 0px;
          display: block;
          min-width: 48px;
          width: 48px;
          height: 48px;
          line-height: 0;
          padding: 0;
          margin: auto;
          background: url('../../images/show.png') no-repeat center;
          background-size: 25px;
          border: none;

          &.toggle-hide{
            background-image: url('../../images/hide.png');
          }
        }
      }

      .check-list{

        .check-list-heading{
          margin-left: 35px;
          margin-bottom: 10px;
        }

        .check{
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }

        .check-status{
          margin-bottom: 3px;
        }
      }

      .password-indicator {
        margin-left: 160px;

        .row {
          margin: 0;
          width: 200px;
        }

        .password-indicator {
          margin-right: -5px;
        }

        .password-strength-text {
          text-align: right;
          font-size: 1.2rem;
          // margin-top: 20px;
        }

        div[class^="indicator-"] {
          height: 3px;
          background-color: grey;
          border-right: 5px solid white;
        }

        .password-weak {
          .indicator-1 {
            background-color: red;
          }

          .indicator-2 {
            background-color: lightgrey;
          }

          .indicator-3 {
            background-color: lightgrey;
          }

          .indicator-4 {
            background-color: lightgrey;
          }
        }

        .password-relative-weak {
          .indicator-1 {
            background-color: orange;
          }

          .indicator-2 {
            background-color: orange;
          }

          .indicator-3 {
            background-color: lightgrey;
          }

          .indicator-4 {
            background-color: lightgrey;
          }
        }

        .password-medium {
          .indicator-1 {
            background-color: yellow;
          }

          .indicator-2 {
            background-color: yellow;
          }

          .indicator-3 {
            background-color: yellow;
          }

          .indicator-4 {
            background-color: lightgrey;
          }
        }

        .password-strong {
          .indicator-1 {
            background-color: green;
          }

          .indicator-2 {
            background-color: green;
          }

          .indicator-3 {
            background-color: green;
          }

          .indicator-4 {
            background-color: green;
          }
        }
      }
    }
  }
}

// mobile device
@media (max-width: $screen-sm-min){
  body{
    &.password{
      form{
        .body{
          visibility: hidden;
          margin: 0px;
          height: 0px;
        }

        .field-container{
          display: block;

          .password-toggle{
            min-width: 35px;
            width: 35px;
            height: 35px;
            background-size: 20px;
          }
        }

        .check-list{
          float: none !important;
          margin-top: 20px;
          margin-bottom: 30px;

          .check-list-heading{
            margin-left: 0px;
            margin-bottom: 30px;
          }
        }
        .password-indicator {
          float: right;
          margin-bottom: 10px;
        }
      }
    }
  }
}
