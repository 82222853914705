.welcome-modal-wrapper {
    min-height: 100%;
    background-color: #4c4c4c;
    display: grid;
    place-items: center;
    padding: 24px;

    .content-box {
        max-width: 560px;
        padding: 48px 24px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
    }

    .security-img {
        width: 100px;
        height: 100px;
        padding: 15px;
        background-color: #e3e3e3;
        border-radius: 50%;
        margin-bottom: 24px;
    }

    h1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 24px;
    }

    .benefit-list {
        margin-bottom: 24px;
        padding-left: 0;
    }

    .benefit-items {
        display: grid;
        grid-template-columns: 32px 1fr;
        width: 100%;
        gap: 16px;

        &:first-child {
            margin-bottom: 16px;
        }

        &.icon-center {
            align-items: center;
        }

        span, li {
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
        }

        svg {
            height: 32px;
        }
    }

    .child-list {
        list-style-type: disc;
        padding-left: 24px;
    }

    .btn-create-password {
        margin-top: 0;
        font-weight: 400;
    }

    @media (min-width: $screen-md-min) {
        .content-box {
            padding: 48px;
        }
    }
}
