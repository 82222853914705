$icon-font-path: "~bootstrap/fonts/";
$icon-font-svg-id: "glyphicons_halflingsregular";

$font-roboto-thin: "../fonts/roboto-thin";
$font-roboto-light: "../fonts/roboto-light";
$font-roboto-regular: "../fonts/roboto-regular";
$font-roboto-medium: "../fonts/roboto-medium";
$font-roboto-bold: "../fonts/roboto-bold";

$brown: #7d7163;
$yellow: #fc0;
$blue-dark: #035;
$bright-blue: #4AB8E4;
$cyan: #5ca5b5;
$turquoise: #66b587;
$gray: #231f20;

$red: #ff0000;
$green: #009900;
$blue: #0066cc;
$white: #FFFFFF;

$gray1: lighten(black, 10%);
$gray2: lighten(black, 20%);
$gray3: lighten(black, 30%);
$gray4: lighten(black, 40%);
$gray5: lighten(black, 50%);
$gray6: lighten(black, 60%);
$gray7: lighten(black, 70%);
$gray8: lighten(black, 80%);
$gray9: lighten(black, 90%);
$gray10: lighten(black, 96%);

$bg-grey: #c6c6c6;
$bd-grey: #7a7a7a;
$btn-disabled-bg: #D1d1d1;
$blue-link: #4A90E2;
$text-grey: #7a7a7a;
$link-cc: #0085C1;

$screen-tablet: 480px;
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-md-max: 1024px;
$screen-lg-min: 1025px;
