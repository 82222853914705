body {
    &.reset-pin {
        form {
            .body {
                margin-bottom: 30px;
            }

            .pin-container {
                + .pin-container {
                    margin-top: 30px;
                }
            }
        }
    }
}

@media (max-width: $screen-sm-min) {
    body {
        &.password {
            form {
                .body {
                    visibility: hidden;
                    margin: 0;
                    height: 0;
                }
            }
        }
    }
}

.resetPin {
    .help-block {
        color: transparent;
        transition: color ease-in-out .2s;
    }
    .has-error {
        .help-block {
            color: $red;
        }
    }
    &-container {
        margin-top: 24px;
    }
    .step {
        &-said {
            .btn-control-container {
                margin-top: 40px;
            }
        }
        &-otp {
            &__heading {
                margin-bottom: 20px;
                line-height: 24px;
                font-size: 14px;
                @media (min-width: $screen-xs-min) {
                    margin-bottom: 32px;
                    font-size: 18px;
                }
            }
            &__header {
                height: 130px;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (min-width: $screen-xs-min) {
                    justify-content: flex-start;
                }
                p {
                    margin-bottom: 0;
                }
                .progress-circle {
                    margin-right: 16px;
                    .seconds,
                    span {
                        color: $gray;
                    }
                }
                &__icon {
                    margin-right: 16px;
                    width: 45px;
                    height: 45px;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            &__input-container {
                margin-bottom: 24px;
                display: flex;
                justify-content: center;
                @media (min-width: $screen-xs-min) {
                    justify-content: flex-start;
                }
            }
            .mb-3 {
                margin-bottom: 3rem;
            }
        }
        &-card {
            &__heading {
                margin-bottom: 24px;
                font-size: 16px;
                line-height: 24px;
            }
            &__main {
                display: flex;
                flex-flow: column;
                align-items: center;
                margin-bottom: 40px;
                @media (min-width: $screen-xs-min) {
                    flex-flow: row;
                    justify-content: space-between;
                    margin-bottom: 20px;
                }
                .input-container {
                    width: 100%;
                    @media (min-width: $screen-xs-min) {
                        max-width: 240px;
                    }
                }
                .img-container {
                    width: 100%;
                    @media (min-width: $screen-xs-min) {
                        max-width: 280px;
                    }
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            &__footer {
                .btn-secondary {
                    padding: 12px 30px;
                }
            }
        }
        &-reset {
            &__heading {
                margin-bottom: 16px;
                font-size: 16px;
                line-height: 24px;
                @media (min-width: $screen-xs-min) {
                    margin-bottom: 40px;
                }

            }
            .input-otp-container {
                margin-bottom: 24px;
            }
            &__main {
                margin-bottom: 20px;
                display: flex;
                flex-flow: column;
                align-items: center;
                @media (min-width: $screen-xs-min) {
                    align-items: flex-start;
                }
            }
            &.resetPassword {
                .step-reset__main {
                    @media (min-width: $screen-sm-min) {
                        display: block;
                    }
                    .row {
                        display: flex;
                        flex-flow: column-reverse;
                        align-self: stretch;
                        @media (min-width: $screen-sm-min) {
                            display: block;
                        }
                    }
                    .check-list{
                        margin-bottom: 16px;
                        @media (min-width: $screen-sm-min) {
                            margin-bottom: 0;
                        }
                    }
                    .help-block {
                        text-align: left;
                    }
                }
                .step-reset__heading {
                    margin-bottom: 0;
                    @media (min-width: $screen-sm-min) {
                        margin-bottom: 16px;
                    }
                    p {
                        @media (min-width: $screen-sm-min) {
                            font-size: 18px;
                        }
                    }
                    p:first-child {
                        margin-bottom: .5rem;
                    }
                }
            }
        }
        &-success {
            .img-container {
                margin: 24px auto;
                text-align: center;
                @media (min-width: $screen-xs-min) {
                    text-align: left;
                }
            }
            &__heading {
                margin-bottom: 40px;
                font-size: 16px;
                text-align: center;
                @media (min-width: $screen-xs-min) {
                    text-align: left;
                }
            }
        }
        &-mcq {
            &__heading {
                margin-bottom: 50px;
                line-height: 24px;
            }
        }
    }
}


#modalSkipMCQResetPin {
    .modal-body {
        .list-options {
           margin-bottom: 0px;
           line-height: 24px;
        }

        ul {
            margin-bottom: 42px;
            padding-left: 20px;

            li {
                line-height: 24px;
            }
        }
    }
}



