@mixin borderRadius($radius){
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin boxSizing($type){
	box-sizing: $type;
	-moz-box-sizing: $type;
	-webkit-box-sizing: $type;
}

@mixin transform($arguments){
  -wekkit-transform: $arguments;
  -moz-transform: $arguments;
  -ms-transform: $arguments;
  -o-transform: $arguments;
  transform: $arguments;
}

@mixin animation($arguments) {
  -webkit-animation: $arguments;
  -moz-animation: $arguments;
  -ms-animation: $arguments;
  -o-animation: $arguments;
  animation: $arguments;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$font-roboto-thin}.woff2') format('woff2'),
       url('#{$font-roboto-thin}.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$font-roboto-light}.woff2') format('woff2'),
       url('#{$font-roboto-light}.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$font-roboto-regular}.woff2') format('woff2'),
       url('#{$font-roboto-regular}.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$font-roboto-medium}.woff2') format('woff2'),
       url('#{$font-roboto-medium}.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('#{$font-roboto-bold}.woff2') format('woff2'),
       url('#{$font-roboto-bold}.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

.text-red {
  color: $red !important;
}

.d-inline-block {
  display: inline-block;
}

.hover-no-underline {
  &:hover {
    text-decoration: none;
  }
}

.mobile-text {
  text-align: left !important;
}

@media (max-device-width: $screen-xs-min){
  .mobile-text {
    text-align: center !important;
  }
}