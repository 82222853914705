.modal-open{
  overflow-y: scroll;
  //padding-right: 0 !important;

  .modal{
    .modal-dialog{
      // width: 750px;
      margin: 180px auto;
    }

    .modal-content{
      overflow: hidden;
      @include borderRadius(3px);
      border: none;

      .row{
        margin: 0;

        [class^="col-md-"]{
          padding: 0;
        }
      }

      .modal-header{
        padding: 30px 50px;
        border-bottom: none;
        background-color: $gray;

        [class^="col-md-"]{
          float: none;
          display: inline-block;
          vertical-align: middle;
        }

        h2{
          font-size: 25px;
          color: white;
          margin: 0;
        }
      }

      .modal-body{
        padding: 30px 50px;

        p{
          font-size: 16px;

          &:not(.help-block){
            color: black;
          }
        }

        .loading{
          position: relative;
          height: 150px;

          .overlay{
            display: none;
          }

          img{
            width: 78px;
            height: 78px;
          }
        }

        .react-countdown-clock{
          margin: 40px auto 0;
        }
      }

      .modal-footer{
        padding: 40px 50px;
        border-top: none;
        text-align: left;

        .btn{
          margin-top: 0;
          + .btn{
            margin-left: 0;
            margin-right: 25px;
          }
        }
      }
    }
  }
}

// mobile device
@media (max-width: $screen-sm-min){
  .modal-open{
    .modal{
      .modal-dialog{
        width: 80%;
        margin: 25px auto;
      }

      .modal-content{
        .modal-header{
          padding: 20px 15px;

          [class^="col-md-"]{
            display: block;
            text-align: center;
          }

          img{
            margin-bottom: 10px;
          }

          h2{
            font-size: 14px;
          }

          .react-countdown-clock{
            position: relative;
            margin: 0 auto 10px;

            canvas{
              left: 0;
            }
          }
        }

        .modal-body{
          padding: 20px 15px;

          p{
            font-size: 14px;
          }
        }

        .modal-footer{
          padding: 20px 15px;

          .btn + .btn{
            margin-top: 20px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
}

.modal-timeout-content {
  strong {
   font-size: 14px;
   @media (min-width: $screen-sm-min) {
     font-size: 16px;
   }
  }
}

/* Resin Pin New Flow */
.modal-template {
  .modal-dialog {
    @media (min-width: $screen-xs-min) {
      padding: 0;
      max-width: 640px;
      width: 640px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .modal-header {
    text-align: left;
    display: flex;
    align-items: center;
    background-color: #231f20;

    button {
      display: none;
    }

    img {
      width: 45px;
      height: 45px;
      margin-right: 32px;
      margin-bottom: 0 !important;
    }
    span {
      display: block;
    }
    @media (min-width: $screen-xs-min) {
      padding: 20px 45px !important;
    }
  }

  .modal-title {
    font-size: 14px;
    color: $white;
    @media (min-width: $screen-xs-min) {
      font-size: 20px;
    }
  }

  .modal-body {
    p {
      line-height: 24px;
      font-size: 16px !important;
    }
    p:last-of-type {
      margin-bottom: 48px;
    }
    .clock-container {
      display: flex;
      justify-content: center;
    }
    .progress-circle {
      .seconds {
        color: $gray;
        span {
          color: $gray;
        }
      }
    }
    @media (min-width: $screen-xs-min) {
      padding: 35px 50px !important;
    }
  }
}
/* ================= */
