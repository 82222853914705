.form-control{
  width: 360px;
  height: 48px;
  font-size: 20px;
  border-color: $gray4;
  @include borderRadius(0);
  box-shadow: none;

  &:focus{
    border-width: 2px;
    border-color: $yellow;
    box-shadow: none;
  }
}

.has-error{
  .form-control{
    border-width: 2px;
    border-color: $red;

    &:focus{
      border-color: $red;
      box-shadow: none;
    }
  }

  .help-block{
    font-size: 16px;
    color: $red;

    span{
      color: $red;
    }
  }
}

// mobile device
@media (max-width: $screen-sm-min){
  form{
    max-width: 290px;
    margin: 0 auto;

    .form-control{
      width: 100%;
      height: 35px;
      font-size: 16px;
    }
  }

  .has-error{
    .help-block{
      font-size: 14px;
      text-align: center;
    }
  }
}

