.input-card-pan {
  &-container {
    .has-error {
      .input-control {
        border-bottom-color: $red;
      }
    }
    .help-block {
      min-height: 20px;
      text-align: left;
      color: $red !important;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 0;
    }
  }
  &__header {
    font-size: 16px;
    color: $gray;
    line-height: 24px;
    margin-bottom: 8px;
    font-weight: 300;
  }
  &__main {
    display: flex;
    justify-content: center;
    flex-flow: row;
    align-items: center;
    @media (min-width: $screen-xs-min) {
      justify-content: flex-start;
    }
  }
  &-item-container {
    input {
      width: 28px;
      height: 32px;
      border-bottom: 2px $bd-grey solid;
      border-top: none;
      border-left: none;
      border-right: none;
      text-align: center;
      margin-right: 12px;
      font-size: 24px;
      font-weight: 300;
      color: $gray;
      padding: 8px 4px;
      &:hover,
      &:focus {
        border-bottom-color: $yellow;
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
      }
      @media (min-width: $screen-xs-min) {
        width: 32px;
        height: 40px;
        font-size: 32px;
      }
    }
  }
}
