.password-strength {
  &-container {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    margin-bottom: 2rem;
  }
  &__text {
    font-size: 12px;
    line-height: 16px;
    min-height: 16px;
    @media (min-width: $screen-sm-min) {
      font-size: 14px;
    }
  }
  &__indicator {
    &-container {
      display: flex;
      justify-content: space-between;
      width: 50%;
    }
    &-item {
      height: 3px;
      width: 24%;
      background-color: grey;
      &.light-grey {
        background-color: lightgrey;
      }
      &.red {
        background-color: red;
      }
      &.orange {
        background-color: orange;
      }
      &.yellow {
        background-color: yellow;
      }
      &.green {
        background-color: green;
      }
    }
  }
}
