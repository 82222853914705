body {
    &.credit-card {
        .grecaptcha-badge{
            display: none;
        }

        .header {
            .heading {
                font-weight: 300;
                line-height: 36px;
                @media (max-width: $screen-sm-min){
                    font-size: 20px;
                    line-height: 24px;
                 }
            }
        }

        .credit-card-step-bar {
            margin-bottom: 35px;
        }

        .verify-cellphone {
            strong {
                color: $white;
            }
        }

        .sub-heading {
            width: 90%;
            line-height: 28px;
            @media (max-width: $screen-sm-min){
                width: 100%;
            }

            &-tax{
                width: 83%;
                @media (max-width: $screen-sm-min){
                    width: 100%;
                }
            }
        }

        .btn-control-container {
            @media (min-width: $screen-sm-min) {
                .btn-back-login {
                    padding: 12px 20px;
                }
            }
        }

        .input-otp-container {
            margin-bottom: 15px;
            .input-otp__main {
                margin-bottom: 5px;
            }
        }

        .help-block {
            font-size: 14px;
        }

        .pre-heading-tax {
            margin-bottom: 5px;
        }

        &.modal-open .modal .modal-content .modal-body p {
            &.text-contact {
                @media (min-width: $screen-sm-min) {
                    width: 90%
                }
            }

            &.card-not-active {
                width: 90%;
                @media (min-width: $screen-sm-min) {
                    width: 70%;
                }
            }
        }
    }
}

.selection-header {
    font-weight: 300;
    height: 320px;
    padding-top: 100px;

    p { 
        margin: 0;
        padding: 0;
    }

    .header-image {
        height: 320px!important;
    }

    .heading {
        padding: 15px 0 30px;
    }

    .sub-heading {
        .credit-card-selection & {
            line-height: 28px;
            width: 83%;
        }
    }
}

.selection-content {
    padding: 10px 0 0 0;

    .you-need-info {
        p,
        ul,
        ul, li {
            margin: 0;
            padding: 0;
        }
        p, ul li {
            font-size: 18px;
            line-height: 28px;
            @media (max-width: $screen-sm-min){
                font-size: 14px;
                line-height: 20px;
            }
        }

        ul li {
            list-style-position: inside;
        }
        margin-bottom: 50px;

        @media (max-width: $screen-sm-min){
           margin-bottom: 20px;
        }
    }

    .question,
    .contact {
        font-size: 14px;
        line-height: 18px;
    }

    .contact {
        width: 60%;
        margin-top: 20px;
        margin-bottom: 40px;
        @media (max-width: $screen-sm-min){
            width: 100%;
        }
        strong {
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.privacy-policy {
    margin-top: 30px;
    @media (max-width: $screen-sm-min){
        padding: 0 15px;
    }
}

.step-creditcard-pan {
    &__input {
        margin-bottom: 60px;
        @media (max-width: $screen-sm-min){
            margin-bottom: 0;
        }
    }
    &__buttons {
        @media (max-width: $screen-sm-min){
            clear: both;
        }
    }
    &__image {
        text-align: right;
        @media (max-width: $screen-sm-min){
            img:not(.logo) {
                width: 95%;
                margin-bottom: 30px;
            }
        }
    }
}

.step-creditcard-said {
    &__input {
        margin-bottom: 24px;
    }
}

.credit-card-selection {
    .btn-selection {
        font-size: 16px;
        color: $gray;
        font-weight: normal;
    }
}

.step-creditcard-tax {
    .btn-control-container-crs {
        display: flex;
        flex-direction: column;
        width: 320px;
        height: 48px;
        margin: 5px auto 0 auto;
        .btn-secondary {
            margin-bottom: 20px;
            margin-right: 0;
        }
        @media (max-width: $screen-sm-min){
            width: auto;
            text-align: center;

            .tyme-btn,
            .btn-secondary {
                margin: 0 auto!important;
                margin-bottom: 25px!important;
            }
        }
    }
}

.step-creditcard-pin {
    &__heading {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 24px;
        @media (min-width: $screen-xs-min) {
            width: 60%;
            margin-bottom: 30px;
            font-size: 18px;
            line-height: 28px;
        }

    }

    &__content {
        margin-bottom: 5px;
        display: flex;
        flex-flow: column;
        align-items: center;
        @media (min-width: $screen-xs-min) {
            align-items: flex-start;
            margin-bottom: 10px;
        }

        .input-otp-container {
            margin-bottom: 5px;
        }
    }
}

.step-creditcard-password {
    &__heading {
        margin-bottom: 0;
        @media (min-width: $screen-sm-min) {
            margin-bottom: 16px;
        }
        p {
            @media (min-width: $screen-sm-min) {
                font-size: 18px;
            }
        }
        p:first-child {
            margin-bottom: 40px;
        }
    }

    &__content {
        @media (min-width: $screen-sm-min) {
            display: block;
        }

        .input-container {
            margin-bottom: 18px;
        }

        .check-list{
            margin-bottom: 40px;
            @media (min-width: $screen-sm-min) {
                margin-bottom: 0;
                padding-left: 10%;
            }

            .check-list__heading {
                font-size: 14px;
                line-height: 24px;
            }

            .check-list__item {
                font-size: 12px;
                line-height: 12px;
            }
        }
        .help-block {
            text-align: left;
            margin-top: 8px;
        }
    }

    &__footer {
        @media (min-width: $screen-sm-min) {
            margin-top: 10px;
        }
    }

    &__link {
        margin-top: 25px;
        text-align: center;

        a {
            cursor: pointer;
            color: $link-cc;
        }

        p {
            width: 90%;
            margin: 0 auto;
            font-size: 14px;
            margin-top: 10px;
            line-height: 20px;
        }

        @media (min-width: $screen-sm-min) {
            text-align: left;
            p {
                width: 100%;
                font-size: 16px;
            }
        }
    }
}

.page-infomation {
    #root, .wrapper, .layout {
        height: auto;

        @media (min-width: $screen-sm-min) {
            height: 100%;
        }
    }
}

.step-information {
    background-image: url('../../images/bg-successful.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 1;


    &_unsuccessful {
        background-image: url('../../images/bg-pending.png');
    }
    
    &__heading {
        font-size: 25px;
        line-height: 30px;
        margin: 30px 0 40px;
        color: $white;

        span {
            color: $yellow;
            font-size: 25px;
            line-height: 25px;
        }

        .sub-text {
            font-size: 18px;
            line-height: 20px;
            color: $white;
            margin-top: 10px;

            @media (min-width: $screen-sm-min) {
                font-size: 20px;
                line-height: 24px;
            }
        }

        @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
            font-size: 48px;
            line-height: 55px;
            margin: 60px 0 60px;

            span {
                font-size: 48px;
                line-height: 55px;
            }
        }

        @media (min-width: $screen-lg-min) {
            font-size: 50px;
            line-height: 60px;
            margin: 60px 0 60px;

            span {
                font-size: 50px;
                line-height: 60px;
            }
        }
    }

    &__note {
        font-size: 20px;
        line-height: 20px;
        font-weight: 500;
        color: $white;

        @media (min-width: $screen-sm-min) {
            font-size: 25px;
            line-height: 24px;
        }
    }
}

.app-download-container {
    padding: 40px 0;

    @media (min-width: $screen-sm-min) {
        padding: 12px 20px;
    }

    .app-download-button {
        padding: 12px 20px;
    }

    .avaible-app {
        font-size: 16px;
        line-height: 14px;
        color: $white;
        margin: 50px 0 15px;
    }

    .btn-download-app-desktop {
        a {
            margin-right: 11px;
        }
    }

    .app-download {
        text-align: center;
        color: $white;
        margin-bottom: 40px;

        @media (min-width: $screen-sm-min) {
            margin: 80px 0 40px;
        }
    
        &__image {
            margin: 0 auto;

            .icon-tymebank {
                width: 48px;
                margin-bottom: 20px;
            }
            .icon-money {
                width: 61px;
            }
            .icon-card {
                width: 68px;
                margin-bottom: 20px;
            }

            @media (min-width: $screen-sm-min) {
                height: 70px;
                margin: 0 auto 35px auto;

                .icon-tymebank {
                    width: 48px;
                    margin-bottom: 0;
                }
                .icon-money {
                    width: 61px;
                }
                .icon-card {
                    width: 68px;
                    margin-bottom: 0;
                }
            }
        }

        &__title,
        &__description {
            color: $white;
        }
    
        &__title {
            font-size: 18px;
            line-height: 20px;
            font-weight: 500;
            margin-bottom: 10px;

            @media (min-width: $screen-sm-min) {
                font-size: 20px;
                line-height: 24px;
            }
        }
    
        &__description {
            font-size: 16px;
            line-height: 20px;
            font-weight: 300;
            margin: 0 auto;
            text-align: center;

            @media (min-width: $screen-sm-min) {
                font-size: 20px;
                line-height: 24px;
                width: 91%;
                line-height: 28px;
            }
        }
    }
}