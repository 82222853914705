.pin-container{
  .form-group{
    display: inline-block;
    margin-left: 20px;
    margin-bottom: 0;

    &:first-of-type{
      margin-left: 0;
    }

    &:last-of-type{
      display: block;
      margin: 0;
    }

    .form-control{
      width: 48px;
      text-align: center;
    }

    &.has-error{
      .help-block{
        display: none;
      }

      &:last-of-type{
        .help-block{
          display: block;
        }
      }
    }
  }
}

// mobile device
@media (max-width: $screen-sm-min){
  .pin-container{
    text-align: center;

    .form-group{
      .form-control{
        width: 35px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .pin-wrapper{
      display: inline-block;
      max-width: 200px;
    }
  }
}
