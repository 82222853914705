body{
    &.reset-information{
      .grecaptcha-badge{
        display: none;
      }

      .bodyCopy{
        margin-bottom: 0px;
      }

      .buttonWrapper{
        margin-top: 40px;
        .resetPassword{
          margin-right: 40px;
        }
      }
    }
  }

  // mobile device
@media (max-width: $screen-sm-min){
  body{
    &.reset-information{
      .buttonWrapper{
        margin-top: 40px;
        .resetPassword{
          margin-right: auto;
        }
      }
    }
  }
}
