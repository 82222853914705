.stepbar {
  &-item {
    flex: 1;
    color: $gray;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 34px;
    position: relative;
    &:after {
      display: block;
      content: '';
      height: 4px;
      width: 100%;
      background-color: $bg-grey;
      margin-top: 1rem;
    }
  }
  &-progress{
    display: block;
    content: '';
    height: 4px;
    background-color: $yellow;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
