.check-list {
  &__heading {
    font-size: 14px;
    line-height: 24px;
    color: #231F20;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    margin-bottom: .5rem;
    @media (min-width: $screen-xs-min) {
      font-size: 16px;
    }
    @media (min-width: $screen-md-min) {
      font-size: 20px;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #231F20;
    line-height: 18px;
    font-family: "Roboto", sans-serif;
    margin-bottom: .25rem;
    img {
      max-width: 15px;
      margin-right: .5rem;
      opacity: 1;
    }
    @media (min-width: $screen-xs-min) {
      font-size: 14px;
      margin-bottom: .5rem;
    }
    @media (min-width: $screen-md-min) {
      font-size: 16px;
      margin-bottom: .75rem;
    }
    &.not-fulfill {
      img {
        opacity: .3;
      }
    }
  }
}
