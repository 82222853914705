.label-container{
  label{
    font-weight: 300;
  }
}

// mobile device
@media (max-width: $screen-sm-min){
  .label-container{
    text-align: left;
  }
}
