.flex-container {
    display: flex;
    flex-flow: column;

    .primary {
        order: 0;
        margin-bottom: 25px !important;
    }
    .secondary {
        order: 1;
    }
}
@media (min-width: $screen-tablet) {
    .flex-container {
        flex-flow: row;
        .primary {
            order: 1;
            margin-bottom: 0;
        }
        .secondary {
            margin-right: 25px;
            order: 0;
        }
    }
}
/* Resin Pin New Flow */
.input-otp {
  &-container {
    display: flex;
    justify-content: center;
    max-width: 240px;
    @media (min-width: $screen-xs-min) {
      justify-content: flex-start;
      max-width: 100%;
    }
    &.disabled {
      .input-otp__header {
        opacity: .5;
      }
    }
    .has-error {
      .input-control {
        border-color: $red;
      }
    }
    .help-block {
      min-height: 20px;
      text-align: left;
      color: $red !important;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 0;
    }
  }
  &__header {
    font-size: 16px;
    color: $gray;
    line-height: 24px;
    margin-bottom: 8px;
    font-weight: 300;
  }
  &__wrapper {
    display: flex;
    flex-flow: column;
  }
  &__main {
    display: flex;
  }
  &-item-container {
    display: inline-block;
    margin-right: 20px;
    input {
      width: 40px;
      height: 40px;
      border: 1px solid $gray;
      text-align: center;

      font-size: 18px;
      font-weight: 700;
      &:hover,
      &:focus {
        border-color: $yellow;
        outline: none;
      }
      &:disabled {
        opacity: .5;
        border-color: $gray;
      }
      @media (min-width: $screen-xs-min) {
        width: 48px;
        height: 48px;
        font-size: 24px;
      }
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}
/* ================= */
